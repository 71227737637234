import { parse } from 'date-fns'
import Immutable from 'immutable'

export const NORDTEC_COLOR_OBJ = { hue: 0, saturation: 77, lightness: 41 }
export const NORDTEC_COLOR = `hsl(0, ${NORDTEC_COLOR_OBJ.saturation}%, ${NORDTEC_COLOR_OBJ.lightness}%)`
export const COLOR_RED = 'rgb(217,11,33)'
export const LINK_COLOR = 'rgb(0, 164, 119)'
export const boxShadow = `0 5px 10px 0 hsla(225, 70%, 10%, 0.1), 0 1px 2px 0 hsla(225, 70%, 10%, 0.3)`
export const hsl = (h, s, l) => `hsl(${h},${s}%,${l}%)`
export const dictionarySort = arr => [
  ...arr.filter(x => x.$ !== undefined).sort((a, b) => a.$.localeCompare(b.$, undefined, { sensitivity: 'base' })),
  ...arr.filter(x => x.$ === undefined).sort((a, b) => a.expression.localeCompare(b.expression, undefined, { sensitivity: 'base' })),
]
export const getNow = () => new Date()
export const getToday = () => {
  const now = getNow()
  return new Date(now.getFullYear(), now.getMonth(), now.getDate())
}
export const parseDateFromDL = dateString => parse(dateString, 'YYYY-MM-DD', new Date())
export const getWeekday = date => (date.getDay() || 7) - 1

export const getFromStorage = (key, defaultValue = null, parse) => {
  if (window.sessionStorage.getItem(key)) {
    return parse === false ? window.sessionStorage.getItem(key) : JSON.parse(window.sessionStorage.getItem(key))
  } else {
    return defaultValue
  }
}

export const setStorage = state => {
  for (const key of Object.keys(state)) {
    window.sessionStorage.setItem(key, JSON.stringify(state[key]))
  }
}

export function fromJS(js) {
  return typeof js !== 'object' || js === null
    ? js
    : Array.isArray(js)
    ? Immutable.Seq(js)
        .map(fromJS)
        .toList()
    : Immutable.Seq(js)
        .map(fromJS)
        .toMap()
}

export function cmp(a, b) {
  if (a > b) return 1
  if (a < b) return -1
  return 0
}
