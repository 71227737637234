import styled, { css } from 'styled-components'
import { COLOR_RED, LINK_COLOR, boxShadow } from '../../js'
import { HANDHELD_UPPER_LIMIT } from '../@'

const HEADER_MARGIN_TOP = 40
const FOOTER_MARGIN_TOP = 40
const FOOTER_HEIGHT = 340

export const NarrowContainer = styled.div(
  ({ center }) => css`
    max-width: 800px;
    margin: 50px auto;
    & p.normal {
      font-weight: normal;
      font-size: 16px;
      font-family: 'Roboto Condensed', sans-serif;
      line-height: 1.4;
      ${center
        ? css`
            text-align: center;
          `
        : ''}
    }
    & h2 {
      color: black;
      font-weight: 300;
      font-size: 40px;
      font-family: 'Roboto Condensed', sans-serif;
      margin-top: 5px;
      margin-bottom: 5px;
      line-height: 1.2em;
      ${center
        ? css`
            text-align: center;
          `
        : ''}
    }
  `
)

export const TopNavLeft = styled.div`
  flex-basis: 25%;
  & img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
  }
`
export const TopNavMiddle = styled.div`
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  & > nav {
    flex: 0 0 0;
    margin-top: auto;
    margin-bottom: 0;
  }
`
export const TopNavRight = styled.div`
  flex-basis: 25%;
  display: flex;
  align-items: center;
  user-select: none;
  cursor: default;
  & span.logout {
    margin-right: 30px;
  }
  & span {
    color: #555;
    text-transform: uppercase;
  }
`

export const Language = styled.div`
  display: flex;
  align-items: center;
  & > span {
    margin-left: 10px;
  }
`

export const Flag = styled.img`
  float: left;
  display: block;
  width: 36px;
  height: auto;
`

export const Title = styled.h1`
  background-color: #f7f7f7;
  height: 140px;
  line-height: 140px;
  font-weight: normal;
  font-size: 50px;
  margin: 0;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;

  @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
    height: 80px;
    line-height: 80px;
    font-size: 30px;
  }
`

export const MobileHeader = styled.header`
  user-select: none;
  display: none;
  @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
    display: block;
  }
`

export const HamburgerContainer = styled.div`
  margin-left: auto;
`

const hamburgerHeight = 30
const hamburgerWidth = 40
export const Hamburger = styled.div`
  display: inline-block;
  background-color: ${COLOR_RED};
  width: ${hamburgerWidth}px;
  height: ${hamburgerHeight / 5}px;
  margin: ${(hamburgerHeight / 5) * 2}px 0px;
  &::before,
  &::after {
    content: '';
    display: block;
    background-color: ${COLOR_RED};
    width: ${hamburgerWidth}px;
    height: ${hamburgerHeight / 5}px;
  }
  &::before {
    transform: translateY(-${(hamburgerHeight / 5) * 2}px);
  }
  &::after {
    transform: translateY(${hamburgerHeight / 5}px);
  }
`

export const MobileHeaderToggler = styled.div`
  display: flex;
  align-items: center;
  & img {
    max-width: 80%;
    max-width: calc(100% - 50px);
    height: auto;
  }
  padding: 10px;
  box-shadow: ${boxShadow};
`

export const MobileHeaderMenu = styled.div`
  display: none;
  &.expanded {
    display: block;
  }
`

export const Header = styled.header`
  margin-bottom: 20px;
  margin-top: ${HEADER_MARGIN_TOP}px;
  display: flex;
  flex-direction: column;
  position: relative;
  @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
    display: none;
  }
`
export const Main = styled.div``

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  min-height: ${FOOTER_HEIGHT}px;
  background-color: #f7f7f7;
  text-align: center;
  margin-top: ${FOOTER_MARGIN_TOP}px;
  & > div.footer-top {
    margin: 35px;
    margin-bottom: 25px;
    display: flex;
    flex-direction: column;
    & img {
      max-width: 300px;
      max-height: 100px;
      @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
        max-width: 80%;
      }
      height: auto;
      margin: auto;
      margin-bottom: 10px;
    }
    & a {
      text-decoration: none;
      font-size: x-large;
      color: ${COLOR_RED};
    }
  }
  & > div.footer-middle {
  }
  & > div.footer-bottom {
    margin: 0 auto;
    margin-top: 25px;
    border-top: 1px solid #ccc;
    padding: 20px 25px 0px 25px;
    & p {
      margin: 0;
      line-height: 1.4;
    }
  }
`

export const Top = styled.div`
  min-height: calc(100vh - ${FOOTER_HEIGHT + HEADER_MARGIN_TOP + FOOTER_MARGIN_TOP}px);
`

export const TopNav = styled.div`
  display: flex;
  & > * {
    flex-grow: 1;
    flex-shrink: 1;
  }
`

export const Links = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
    flex-direction: column;
  }
  @media screen and (min-width: ${HANDHELD_UPPER_LIMIT + 1}px) {
    &::before {
      display: block;
      flex: 100 1 100px;
      content: '';
    }
    &::after {
      display: block;
      flex: 100 1 100px;
      content: '';
    }
  }
`

export const LinkContainer = styled.li`
  display: flex;
  &.mobile {
    display: flex;
    @media screen and (min-width: ${HANDHELD_UPPER_LIMIT + 1}px) {
      display: none;
    }
  }
  letter-spacing: 0.8px;
  font-family: 'Roboto Condensed', sans-seif;
  font-weight: bold;
  list-style: none;
  margin: 0;
  @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
    font-size: larger;
  }
  @media screen and (min-width: ${HANDHELD_UPPER_LIMIT + 1}px) {
    margin-left: 20px;
    margin-right: 20px;
  }

  text-transform: uppercase;
  & > a,
  & > span {
    cursor: pointer;
    padding: 0;
    border: none;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
      border-bottom: 2px solid #ddd;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 8px 15px;
      background-color: transparent;
      color: black;
      &.active {
        color: ${COLOR_RED};
      }
      &:hover {
        background-color: ${COLOR_RED};
        color: white;
      }
    }
    @media screen and (min-width: ${HANDHELD_UPPER_LIMIT + 1}px) {
      padding-top: 3px;
      border-top: 3px solid transparent;
      padding-bottom: 3px;
      border-bottom: 3px solid transparent;
      color: black;
      &.active {
        border-bottom: 3px solid ${COLOR_RED};
        color: ${COLOR_RED};
      }
      &.active-no-underline {
        color: ${COLOR_RED};
      }
    }
  }
`

export const ImageNav = styled.nav`
  display: flex;
  justify-content: center;
  margin: 40px 0px;
  & img {
    width: 190px;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
  & div.description > a {
    text-decoration: none;
    color: ${LINK_COLOR};
    &:hover {
      color: #555;
    }
  }
`

export const ImageContainer = styled.div`
  /*flex: 1 0 100px;*/
  width: 100%;
  max-width: 220px;
  color: black;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  & div.caption,
  & div.description {
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    -ms-word-break: break-all;
    word-break: break-all;
    word-break: break-word;
    line-height: 1.4;
  }
  & div.caption {
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    text-transform: uppercase;
  }
  & div.description {
    font-size: 16px;
    margin-top: 10px;
    max-width: 220px;
  }
  margin: 0 20px;
  @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
    margin: 0 5px;
  }
`
