import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'

const Element = styled.span`
  display: inline-block;
`

const Icon = ({ containerStyle = {}, onClick, iconOnly, ...rest }) => {
  const icon = <FontAwesomeIcon {...rest} />
  if (iconOnly) return icon
  return (
    <Element style={containerStyle} onClick={onClick}>
      {icon}
    </Element>
  )
}

export default Icon
