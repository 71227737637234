import React, { Fragment, Component } from 'react'
import styled from 'styled-components'
import { Map } from 'immutable'
import asyncComponent from '../@/AsyncComponent'
import { BrowserRouter as Router, Switch, Route, NavLink as NavLinkT, Redirect } from 'react-router-dom'
import sv from './sv.png'
import uk from './uk.png'
import post, { apiRoot } from '../../js/fetch-wrappers'
//import Login from '../Login/Login'
//import Order from '../Order/Order'
//import OrderManual from '../OrderManual/OrderManual'
//import Loader from '../Loader/Loader'
//import Delay from '../Delay/Delay'
//import Me from '../Me/Me'
//import Register from '../Register/Register'
//import Calibration from '../Calibration/Calibration'
import 'normalize.css'
import './App.css'
import translator from '../../js/translation'
import { SmartMarginContainer, HANDHELD_UPPER_LIMIT } from '../@'
import dictionary from './App.dictionary'
import { NORDTEC_COLOR, setStorage } from '../../js'
import {
  NarrowContainer,
  TopNavLeft,
  TopNavMiddle,
  TopNavRight,
  Language,
  Flag,
  Title,
  Top,
  TopNav,
  MobileHeader,
  MobileHeaderToggler,
  MobileHeaderMenu,
  Header,
  Main,
  Footer,
  Links,
  LinkContainer,
  HamburgerContainer,
  Hamburger,
  ImageNav,
  ImageContainer,
} from './App.styled'
import { getFromStorage, fromJS } from '../../js'
import img_nordtec from './nordtec.png'
import img_access_small from './access-small.png'
import img_access_plus_small from './access-plus-small.png'
import img_190x190px_bestallkalibrering from './ikoner_access/190x190/190x190px_bestallkalibrering.png'
import img_190x190px_express from './ikoner_access/190x190/190x190px_express.png'
import img_190x190px_loggain from './ikoner_access/190x190/190x190px_loggain.png'
import img_190x190px_minainstrument from './ikoner_access/190x190/190x190px_minainstrument.png'
import img_190x190px_minainstrument_grey from './ikoner_access/190x190/190x190px_minainstrument_grey.png'
import img_190x190px_minasidor from './ikoner_access/190x190/190x190px_minasidor.png'
import img_190x190px_normal from './ikoner_access/190x190/190x190px_normal.png'
import img_190x190px_tidsbokning from './ikoner_access/190x190/190x190px_tidsbokning.png'
import img_190x190px_tidsbokning_grey from './ikoner_access/190x190/190x190px_tidsbokning_grey.png'
import Modal from '../Modal/Modal'
import Icon from '../@/Icon'

const NavLink = styled(NavLinkT)`
  color: black;
  text-decoration: none;
  &:hover {
    color: #555;
  }
`
const CHECK_LOGGED_IN_WAIT = 10000
const Order = asyncComponent(() => import('../Order/Order'))
const OrderManual = asyncComponent(() => import('../OrderManual/OrderManual'))
const Login = asyncComponent(() => import('../Login/Login'))
const RecoverPassword = asyncComponent(() => import('../RecoverPassword/RecoverPassword'))
const Loader = asyncComponent(() => import('../Loader/Loader'))
const Delay = asyncComponent(() => import('../Delay/Delay'))
const Me = asyncComponent(() => import('../Me/Me'))
const MyInstruments = asyncComponent(() => import('../MyInstruments/MyInstruments'))
const Register = asyncComponent(() => import('../Register/Register'))
const Calibration = asyncComponent(() => import('../Calibration/Calibration'))

const loaderStyle = { color: NORDTEC_COLOR }

const renderLink = ({ caption, onClick, to, icon, mobileOnly, exact, activeClassName = 'active' }) => {
  exact = exact === undefined ? true : exact
  return (
    <LinkContainer className={mobileOnly ? 'mobile' : ''}>
      {to ? (
        <NavLink activeClassName={activeClassName} to={to} exact={exact} onClick={onClick}>
          <span>{caption}</span>
        </NavLink>
      ) : (
        <span onClick={onClick}>{caption}</span>
      )}
    </LinkContainer>
  )
}

const renderImage = ({ caption, description, Description, to, image, onClick }) => (
  <>
    <ImageContainer>
      <NavLink activeClassName="active" to={to} onClick={onClick}>
        <img alt={caption} src={image} />
        {caption && <div className="caption">{caption}</div>}
        {description && <div className="description">{description}</div>}
      </NavLink>
      {Description && (
        <div className="description" style={description && { marginTop: 0 }}>
          <Description />
        </div>
      )}
    </ImageContainer>
  </>
)

const Nav = ({ _, loggedIn = false, VIP, language, activeClassName, _this }) => {
  return (
    <nav>
      <Links>
        {renderLink({ caption: _`Start`, to: `${process.env.PUBLIC_URL}/`, activeClassName })}
        {!loggedIn && renderLink({ caption: _`Log In`, to: `${process.env.PUBLIC_URL}/login`, activeClassName })}
        {loggedIn && renderLink({ caption: _`My Pages`, to: `${process.env.PUBLIC_URL}/me`, activeClassName })}
        {loggedIn && VIP && renderLink({ caption: _`My Instruments`, to: `${process.env.PUBLIC_URL}/my-instruments`, activeClassName })}
        {renderLink({ caption: _`$order-calibration`, to: `${process.env.PUBLIC_URL}/order`, exact: false, activeClassName })}
        {renderLink({ caption: _`About Access`, to: `${process.env.PUBLIC_URL}/about`, activeClassName })}
        {renderLink({
          caption: language === 'sv' ? 'Change to English' : 'Byt till Svenska',
          mobileOnly: true,
          onClick: () => _this.setLanguage(language === 'sv' ? 'en' : 'sv'),
        })}
        {loggedIn && renderLink({ caption: _`$logout`, mobileOnly: true, onClick: _this.logout })}
      </Links>
    </nav>
  )
}

const LinkifyEmailAddresses = ({ children }) => {
  const re = /[^@\s]*@[^.]*\.\S+/g
  let results = [],
    lastIndex = 0,
    current
  while ((current = re.exec(children))) {
    const email = current[0]
    const { input, index } = current
    if (index > lastIndex) results.push({ text: input.slice(lastIndex, index) })
    results.push({ email })
    lastIndex = index + email.length
  }
  if (lastIndex < children.length) results.push({ text: children.slice(lastIndex) })
  return (
    <>
      {results.map(({ email, text }, i) => (
        <Fragment key={i}>{email ? <a href={`mailto:${email}`}>{email}</a> : text}</Fragment>
      ))}
    </>
  )
}
/*
const ReplaceTextWithComponentold = ({ children, re, with: Component }) => {
  console.log(children)
  if (!re) throw new Error('ReplaceTextWithComponent: No regular expression provided.')
  if (re.flags.indexOf('g') === -1) throw new Error('ReplaceTextWithComponent: Must use global flag.')
  let results = [],
    lastIndex = 0,
    current
  while ((current = re.exec(children))) {
    const comp = current[0]
    const { input, index } = current
    if (index > lastIndex) results.push({ text: input.slice(lastIndex, index) })
    results.push({ comp })
    lastIndex = index + comp.length
  }
  if (lastIndex < children.length) results.push({ text: children.slice(lastIndex) })
  return (
    <>
      {results.map(({ comp, text }, i) => (
        <Fragment key={i}>{comp ? <Component>{comp}</Component> : text}</Fragment>
      ))}
    </>
  )
}
*/
function ReplaceTextWithComponent({ children, re, with: With }) {
  if (!re) throw new Error('ReplaceTextWithComponent: No regular expression provided.')
  if (re.flags.indexOf('g') === -1) throw new Error('ReplaceTextWithComponent: Must use global flag.')
  function replace(text) {
    let results = [],
      lastIndex = 0,
      current
    while ((current = re.exec(text))) {
      const comp = current[0]
      const { input, index } = current
      if (index > lastIndex) results.push({ text: input.slice(lastIndex, index) })
      results.push({ comp })
      lastIndex = index + comp.length
    }
    if (lastIndex < text.length) results.push({ text: text.slice(lastIndex) })
    return (
      <>
        {results.map(({ comp, text }, i) => (
          <Fragment key={i}>{comp ? <With>{comp}</With> : text}</Fragment>
        ))}
      </>
    )
  }
  function render(children) {
    return React.Children.map(children, childNode => {
      if (typeof childNode === 'string') return replace(childNode) // cover case: <div>text<div></div></div>
      if (typeof childNode.props.children === 'string') return React.cloneElement(childNode, [], replace(childNode.props.children))
      return React.cloneElement(childNode, [], render(childNode.props.children))
    })
  }

  return <>{render(children)}</>
}

const Start = ({ _, loggedIn = false, VIP, language }) => {
  return (
    <NarrowContainer center>
      <SmartMarginContainer>
        {false && <h2>{_`$start-subtitle`}</h2>}
        <p className="normal">{_`$start-welcome-1`}</p>
        <ImageNav>
          {!loggedIn &&
            renderImage({
              caption: (
                <>
                  {_`Log In`}
                  {!loggedIn && (
                    <span style={{ textTransform: 'none', fontSize: 'smaller' }}>
                      <br />({_`For Access customers`})
                    </span>
                  )}
                </>
              ),
              to: `${process.env.PUBLIC_URL}/login`,
              image: img_190x190px_loggain,
            })}
          {loggedIn && renderImage({ caption: _`My Pages`, to: `${process.env.PUBLIC_URL}/me`, image: img_190x190px_minasidor })}
          {loggedIn &&
            renderImage({
              caption: (
                <>
                  {_`My Instruments`}
                  {!VIP && (
                    <span style={{ textTransform: 'none', fontSize: 'smaller' }}>
                      <br />({_`For Access+ customers`})
                    </span>
                  )}
                </>
              ),
              to: `${process.env.PUBLIC_URL}/my-instruments`,
              image: VIP ? img_190x190px_minainstrument : img_190x190px_minainstrument_grey,
              onClick: VIP ? undefined : e => e.preventDefault(),
            })}
          {renderImage({
            caption: (
              <>
                {_`$order-calibration`}
                {!loggedIn && (
                  <span style={{ textTransform: 'none', fontSize: 'smaller' }}>
                    <br />({_`For customer without Access`})
                  </span>
                )}
              </>
            ),
            to: `${process.env.PUBLIC_URL}/order`,
            image: img_190x190px_bestallkalibrering,
          })}
        </ImageNav>

        <p className="normal">
          <ReplaceTextWithComponent
            re={/\$ABOUT_ACCESS/g}
            with={() => <NavLink to={`${process.env.PUBLIC_URL}/about`}>{_`$about`}</NavLink>}>{_`$start-welcome-2`}</ReplaceTextWithComponent>
        </p>

        <p className="normal">
          <LinkifyEmailAddresses>{_`$start-welcome-3`.replace(/\$EMAIL/g, _`$contact-email`)}</LinkifyEmailAddresses>
        </p>
      </SmartMarginContainer>
    </NarrowContainer>
  )
}
/*
const OrderStart = ({ _, loggedIn = false, VIP, language }) => {
  return (
    <ImageNav>
      {!loggedIn && renderImage({ caption: _`Log In`, to: `${process.env.PUBLIC_URL}/login`, image: img_190x190px_loggain })}
      {renderImage({ caption: _`Order Calibration`, to: `${process.env.PUBLIC_URL}/order/specify`, image: img_190x190px_bestallkalibrering })}
    </ImageNav>
  )
}
*/
const OrderSpecify = ({ _, loggedIn = false, VIP, language, setState }) => {
  return (
    <SmartMarginContainer>
      <ImageNav>
        {renderImage({
          caption: _`$normal-title`,
          description: _`$normal-description`,
          to: `${process.env.PUBLIC_URL}/order/manual`,
          image: img_190x190px_normal,
          onClick: () => setState({ express: false }, () => setStorage({ express: false })),
        })}
        {renderImage({
          caption: _`Express`,
          description: _`$express-description`,
          to: `${process.env.PUBLIC_URL}/order/manual`,
          image: img_190x190px_express,
          onClick: () => setState({ express: true }, () => setStorage({ express: true })),
        })}
        {renderImage({
          caption: _`$time-booking`,
          description: _`$time-booking-description`,
          Description: () => (
            <>
              {_`Contact`} <a href={`mailto:${_`$contact-email`}`}>{_`$contact-email`}</a> {_`for more information`}.
            </>
          ),
          to: `${process.env.PUBLIC_URL}/order/time`,
          image: loggedIn ? img_190x190px_tidsbokning : img_190x190px_tidsbokning_grey,
          onClick: loggedIn ? undefined : e => e.preventDefault(),
        })}
      </ImageNav>
    </SmartMarginContainer>
  )
}

const NoMatch = () => <h2>No Match</h2>

const About = ({ _ }) => (
  <NarrowContainer>
    <SmartMarginContainer>
      <p className="normal">{_`$about-top`}</p>
      <h2>{_`About`} Access</h2>
      <p className="normal">{_`$about-access`}</p>
      <h2>{_`About`} Access+</h2>
      <p className="normal">{_`$about-access-plus`}</p>
      <h2>{_`Contact us`}</h2>
      <p className="normal">
        <ReplaceTextWithComponent re={/\$EMAIL/g} with={() => <a href={`mailto:${_`$about-email`}`}>{_`$about-email`}</a>}>
          <ReplaceTextWithComponent re={/\$OURFORM/g} with={() => <a href="https://www.nordtec.se/kontakta-oss">{_`our form`}</a>}>
            {_`$about-bottom`.replace('$TEL', _`$tel`)}
          </ReplaceTextWithComponent>
        </ReplaceTextWithComponent>
      </p>
    </SmartMarginContainer>
  </NarrowContainer>
)

const FlashContainer = styled.div`
  z-index: 1;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100vw;
`

const Flash = styled.div`
  z-index: 2;
  width: 80vw;
  background-color: white;
  cursor: default;
  position: relative;
  border-radius: 4px;
  padding: 20px;
  left: 50%;
  top: 10vh;
  transform: translateX(-50%);
  text-align: center;
  max-width: 500px;
  font-size: 16px;
  font-family: 'Roboto Condensed', sans-serif;
  @media screen and (max-width: ${HANDHELD_UPPER_LIMIT}px) {
    position: absolute;
    transform: none;
    left: 30px;
    top: 50px;
    right: 30px;
    width: unset;
    max-width: unset;
  }
  & div.button-row {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
  & button {
    border: none;
    background: rgba(0, 0, 0, 0.2);
    margin: 0 5px;
  }
  & button.close-button {
    margin: 0;
    position: absolute;
    top: 5px;
    right: 0;
    border: none;
    background: transparent;
  }
`

export const AppContext = React.createContext()

const routes = [
  {
    key: 'root',
    title: '$start-title',
    exact: true,
    path: `${process.env.PUBLIC_URL}/`,
    component: ({ _, loggedIn, VIP, language }) => <Start {...{ _, loggedIn, VIP, language }} />,
  },
  {
    key: 'register',
    title: '$register',
    exact: true,
    path: `${process.env.PUBLIC_URL}/register`,
    component: ({ loggedIn }) => (loggedIn ? <Redirect to={`${process.env.PUBLIC_URL}/`} /> : <Register />),
  },
  {
    key: 'login',
    title: '$login',
    exact: true,
    path: `${process.env.PUBLIC_URL}/login`,
    component: ({ loggedIn }) => (loggedIn ? <Redirect to={`${process.env.PUBLIC_URL}/`} /> : <Login />),
  },
  {
    key: 'recover-password',
    title: '$recover-password',
    exact: true,
    path: `${process.env.PUBLIC_URL}/recover-password/:token?`,
    component: props => (props.loggedIn ? <Redirect to={`${process.env.PUBLIC_URL}/`} /> : <RecoverPassword {...props} />),
  },
  //{
  //  key: 'order',
  //  title: '$order-calibration',
  //  exact: true,
  //  path: `${process.env.PUBLIC_URL}/order`,
  //  component: ({ _, loggedIn, VIP, language }) =>
  //    loggedIn ? <Redirect to={`${process.env.PUBLIC_URL}/order/specify`} /> : <OrderStart {...{ _, loggedIn, VIP, language }} />,
  //},
  {
    key: 'order',
    title: '$order-calibration',
    exact: true,
    path: `${process.env.PUBLIC_URL}/order`,
    component: ({ _, loggedIn, VIP, language, setState }) => <OrderSpecify {...{ _, loggedIn, VIP, language, setState }} />,
  },
  {
    key: 'order/time',
    title: '$time-booking',
    exact: true,
    path: `${process.env.PUBLIC_URL}/order/time`,
    component: ({ loggedIn }) => (loggedIn ? <Order /> : <Redirect to={`${process.env.PUBLIC_URL}/`} />),
  },
  {
    key: 'order/manual',
    title: ({ state, _ }) => <>{state.express ? _`$order-express-calibration` : _`$order-calibration`}</>, //,
    exact: true,
    path: `${process.env.PUBLIC_URL}/order/manual`,
    component: OrderManual,
  },
  {
    key: 'me',
    title: '$me',
    exact: true,
    path: `${process.env.PUBLIC_URL}/me`,
    component: ({ loggedIn }) => (loggedIn ? <Me /> : <Redirect to={`${process.env.PUBLIC_URL}/login`} />),
  },
  {
    key: 'my-instruments',
    title: '$my-instruments',
    exact: true,
    path: `${process.env.PUBLIC_URL}/my-instruments`,
    component: ({ loggedIn, VIP }) => (loggedIn && VIP ? <MyInstruments /> : <Redirect to={`${process.env.PUBLIC_URL}/login`} />),
  },
  {
    key: 'about',
    title: '$start-title',
    exact: true,
    path: `${process.env.PUBLIC_URL}/about`,
    component: ({ _, loggedIn, VIP, language }) => <About {...{ _, loggedIn, VIP, language }} />,
  },
  {
    key: 'code',
    path: `${process.env.PUBLIC_URL}/calibration/:code`,
    component: Calibration,
  },
  {
    key: 'download-order-note',
    path: `${process.env.PUBLIC_URL}/order/:webid/download.pdf`,
    component: ({ webid }) => <Redirect to={`${process.env.PUBLIC_URL}/${webid}/asdfasdf`} />,
  },
  {
    key: 'nomatch',
    component: NoMatch,
  },
]

export default class App extends Component {
  login = async user => {
    try {
      const [instruments, probes, digitalProbes, calibrationArticles, startCost, manual, bookings, addresses, end_customer_addresses] = await post(
        `${apiRoot}/user/fetch-data`
      )
      this.setState({
        loggedIn: true,
        user,
        loading: false,
        instruments,
        probes,
        digitalProbes,
        calibrationArticles,
        startCost,
        manual,
        bookings,
        addresses,
        end_customer_addresses,
      })
    } catch {
      window.setTimeout(() => this.login(user), CHECK_LOGGED_IN_WAIT)
    }
  }
  setLanguage = language => this.setState({ language }, setStorage({ language }))
  setAppState = (newState, cb) => {
    this.setState(newState, cb)
  }
  order = new Map()
  orderManual = window.sessionStorage.getItem('orderManual') === null ? new Map() : fromJS(JSON.parse(window.sessionStorage.getItem('orderManual')))
  state = {
    flash: null,
    dialog: null,
    express: getFromStorage('express', false),
    expanded: false,
    archivedOrder: getFromStorage('archivedOrder', null),
    archivedManualOrder: getFromStorage('archivedManualOrder', null),
    language: getFromStorage('language', 'sv'),
    _: translator(getFromStorage('language', 'sv'), { dictionary }),
    loggedIn: false,
    loading: true,
    user: null,
    instruments: null /* InstrumentID, Marking, Article, ArticleNumber, SerialNumber, InstrumentArticleID, LastCalibrated */,
    probes: null,
    calibrationArticles: null,
    startCost: -1,
    manual: null,
    bookings: null,
    addresses: null,
    end_customer_addresses: null,
    login: this.login,
    setLanguage: this.setLanguage,
    setState: this.setAppState,
    setManualOrderData: arrKeyValue => {
      const orderManual = arrKeyValue.reduce((res, [key, value]) => res.set(key, value), this.orderManual)
      this.orderManual = orderManual
      window.sessionStorage.setItem('orderManual', JSON.stringify(orderManual))
    },
    getManualOrderData: key => this.orderManual.get(key),
    appConfirm: async dialog => {
      this.setState({ dialog })
      return await new Promise(resolve => {
        this.dialogResponse.current = resolve
      })
    },
  }

  constructor(props) {
    super(props)
    if (this.state.archivedOrder) {
      this.state.archivedOrder.selectedSlot.date = new Date(this.state.archivedOrder.selectedSlot.date)
      this.state.archivedOrder.selections = fromJS(this.state.archivedOrder.selections)
    }
    if (this.state.archivedManualOrder) {
      this.state.archivedManualOrder.fieldValues = fromJS(this.state.archivedManualOrder.fieldValues)
    }
    this.dialogResponse = React.createRef()
  }

  logout = async () => {
    const data = await post(`${apiRoot}/logout`)
    if ('success' in data) {
      this.setState({ loggedIn: false, user: null, loading: false, instruments: null, probes: null, calibrationArticles: null, startCost: -1 })
    }
  }

  checkLoggedIn = async () => {
    try {
      if (!this.state.manual) {
        await this.fetchOpenData()
      }
      const { loggedIn, user } = await post(`${apiRoot}/`)
      if (loggedIn && user && !this.state.user) {
        this.login(user)
      }
      if (!loggedIn && this.state.loggedIn) {
        this.setState({ loggedIn, flash: '$logged-out' })
      }
      window.setTimeout(this.checkLoggedIn, CHECK_LOGGED_IN_WAIT)
    } catch {
      window.setTimeout(this.checkLoggedIn, CHECK_LOGGED_IN_WAIT)
    }
  }

  fetchOpenData = async () => {
    try {
      const [manual, cost, texts] = await post(`${apiRoot}/fetch-open-data`)
      this.setState({ loggedIn: false, user: null, manual, loading: false, cost, texts })
    } catch {
      //window.setTimeout(this.fetchOpenData, CHECK_LOGGED_IN_WAIT)
    }
  }

  async componentDidMount() {
    this.checkLoggedIn()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.language !== this.state.language) {
      const { language } = this.state
      const _ = translator(language, { dictionary })
      this.setState({ _ })
    }
  }

  toggleMenu = () => {
    const expanded = !this.state.expanded
    this.setState({ expanded })
  }

  render() {
    const { loggedIn, user, loading, language, setState, flash } = this.state
    const { WebVIP = 'false' } = user || {}
    const VIP = WebVIP.toLowerCase() === 'true'
    const { _, expanded, dialog } = this.state

    return (
      <AppContext.Provider value={this.state}>
        <Router>
          <>
            <Top>
              {flash && (
                <Modal>
                  <FlashContainer>
                    <Flash onClick={() => this.setState({ flash: null })}>
                      <div>{_(flash)}</div>
                      <div className="button-row">
                        <button type="button" onClick={() => this.setState({ flash: null })}>
                          <Icon icon={['fas', 'times']} fixedWidth />
                          {_`CLOSE`}
                        </button>
                      </div>
                    </Flash>
                  </FlashContainer>
                </Modal>
              )}
              {dialog && (
                <Modal>
                  <FlashContainer>
                    <Flash onClick={() => this.setState({ flash: null })}>
                      <div> {dialog.text}</div>
                      <button
                        className="close-button"
                        type="button"
                        onClick={() => {
                          this.setState({ dialog: null })
                          this.dialogResponse.current(undefined)
                        }}>
                        <Icon icon={['fas', 'times']} fixedWidth />
                      </button>
                      <div className="button-row">
                        {(dialog.options || []).map(({ text, value, onClick }, i) => {
                          return (
                            <button
                              key={i}
                              onClick={() => {
                                if (onClick) onClick(value || text)
                                this.setState({ dialog: null })
                                this.dialogResponse.current(value === undefined ? text : value)
                              }}>
                              {text}
                            </button>
                          )
                        })}
                      </div>
                    </Flash>
                  </FlashContainer>
                </Modal>
              )}
              <MobileHeader onClick={this.toggleMenu}>
                <MobileHeaderToggler>
                  <img alt={VIP ? _`Access+ Logo` : _`Access Logo`} src={VIP ? img_access_plus_small : img_access_small} />
                  <HamburgerContainer>
                    <Hamburger />
                  </HamburgerContainer>
                </MobileHeaderToggler>
                <MobileHeaderMenu className={expanded ? 'expanded' : ''}>
                  <Nav {...{ _, loggedIn, VIP, language, _this: this }} />
                </MobileHeaderMenu>
              </MobileHeader>
              <Header>
                <TopNav>
                  <TopNavLeft>
                    <a title={_`Return to Start Page`} href={`${process.env.PUBLIC_URL}/`}>
                      <img alt={_`Return to Start Page`} src={VIP ? img_access_plus_small : img_access_small} />
                    </a>
                  </TopNavLeft>
                  <TopNavMiddle>
                    <Nav {...{ _, loggedIn, VIP, language, _this: this }} />
                  </TopNavMiddle>
                  <TopNavRight>
                    {loggedIn && <span className="logout" onClick={this.logout}>{_`$logout`}</span>}
                    <Language
                      onClick={() => this.setLanguage(language === 'sv' ? 'en' : 'sv')}
                      title={language === 'sv' ? 'Change to English' : 'Byt till Svenska'}>
                      <Flag alt={language === 'sv' ? 'Change to English' : 'Byt till Svenska'} src={language === 'sv' ? uk : sv} />
                      <span>{language === 'sv' ? 'English' : 'Svenska'}</span>
                    </Language>
                  </TopNavRight>
                </TopNav>
              </Header>
              <Title>
                <Switch>
                  {routes
                    .filter(({ title }) => title)
                    .map(({ component: Component, title, ...props }) => (
                      <Route {...props} exact render={props => (typeof title === 'string' ? _(title) : title({ state: this.state, _ }))} />
                    ))}
                </Switch>
              </Title>
              <Main>
                {loading ? (
                  <Delay ms={500}>
                    <Loader width={150} height={150} style={loaderStyle} spin />
                  </Delay>
                ) : (
                  <Switch>
                    {routes.map(({ component: Component, ...props }) => (
                      <Route
                        {...props}
                        render={props => <Component _={_} loggedIn={loggedIn} VIP={VIP} setState={setState} language={language} {...props} />}
                      />
                    ))}
                  </Switch>
                )}
              </Main>
            </Top>
            <Footer>
              <div className="footer-top">
                <a href="http://www.nordtec.se" title={_`Return to Nordtec`}>
                  <img alt={_`Return to Nordtec`} src={img_nordtec} />
                </a>
                <a href="http://www.nordtec.se" title={_`Return to Nordtec`}>
                  www.nordtec.se
                </a>
              </div>
              <div className="footer-middle">
                <Nav {...{ _, loggedIn, VIP, language, activeClassName: 'active-no-underline', _this: this }} />
              </div>
              <div className="footer-bottom">
                <p>
                  {_`$footer-closing-text`}
                  <br />
                  &copy; {new Date().getFullYear()} | {_`$company-name`} | {_`$telephone`} | {_`$company-slogan`} |{' '}
                  <NavLink to={`${process.env.PUBLIC_URL}/`}> {_`Cookie Policy`}</NavLink>
                </p>
              </div>
            </Footer>
          </>
        </Router>
      </AppContext.Provider>
    )
  }
}
