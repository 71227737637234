// README src\components\@\fontawesome-pro\fontawesome-pro-5.15.4-web\_pw
import { library } from '@fortawesome/fontawesome-svg-core'

// SOLID
import { fasAddressCard } from './fontawesome-pro/output/fasAddressCard.js'
import { fasAngleLeft } from './fontawesome-pro/output/fasAngleLeft.js'
import { fasAngleRight } from './fontawesome-pro/output/fasAngleRight.js'
import { fasBuilding } from './fontawesome-pro/output/fasBuilding.js'
import { fasCaretSquareDown } from './fontawesome-pro/output/fasCaretSquareDown.js'
import { fasCheckCircle } from './fontawesome-pro/output/fasCheckCircle.js'
import { fasCircle } from './fontawesome-pro/output/fasCircle.js'
import { fasCircleNotch } from './fontawesome-pro/output/fasCircleNotch.js'
import { fasCity } from './fontawesome-pro/output/fasCity.js'
import { fasEnvelope } from './fontawesome-pro/output/fasEnvelope.js'
import { fasFingerprint } from './fontawesome-pro/output/fasFingerprint.js'
import { fasFlagAlt } from './fontawesome-pro/output/fasFlagAlt.js'
import { fasHandReceiving } from './fontawesome-pro/output/fasHandReceiving.js'
import { fasIdBadge } from './fontawesome-pro/output/fasIdBadge.js'
import { fasMapMarker } from './fontawesome-pro/output/fasMapMarker.js'
import { fasMapPin } from './fontawesome-pro/output/fasMapPin.js'
import { fasMinusSquare } from './fontawesome-pro/output/fasMinusSquare.js'
import { fasMobile } from './fontawesome-pro/output/fasMobile.js'
import { fasPhone } from './fontawesome-pro/output/fasPhone.js'
import { fasPhoneOffice } from './fontawesome-pro/output/fasPhoneOffice.js'
import { fasPlusSquare } from './fontawesome-pro/output/fasPlusSquare.js'
import { fasPrint } from './fontawesome-pro/output/fasPrint.js'
import { fasSearch } from './fontawesome-pro/output/fasSearch.js'
import { fasSignIn } from './fontawesome-pro/output/fasSignIn.js'
import { fasSignOut } from './fontawesome-pro/output/fasSignOut.js'
import { fasTimes } from './fontawesome-pro/output/fasTimes.js'
import { fasThumbsUp } from './fontawesome-pro/output/fasThumbsUp.js'
import { fasToggleOn } from './fontawesome-pro/output/fasToggleOn.js'
import { fasToggleOff } from './fontawesome-pro/output/fasToggleOff.js'
import { fasTruck } from './fontawesome-pro/output/fasTruck.js'
import { fasUndo } from './fontawesome-pro/output/fasUndo.js'

// REGULAR
import { farArrowLeft } from './fontawesome-pro/output/farArrowLeft.js'
import { farBadgePercent } from './fontawesome-pro/output/farBadgePercent.js'
import { farCalendarAlt } from './fontawesome-pro/output/farCalendarAlt.js'
import { farCoin } from './fontawesome-pro/output/farCoin.js'
import { farCoins } from './fontawesome-pro/output/farCoins.js'
import { farDownload } from './fontawesome-pro/output/farDownload.js'
import { farGlobeAfrica } from './fontawesome-pro/output/farGlobeAfrica.js'
import { farMinusCircle } from './fontawesome-pro/output/farMinusCircle.js'
import { farSortAlphaDown } from './fontawesome-pro/output/farSortAlphaDown.js'
import { farSortAlphaUp } from './fontawesome-pro/output/farSortAlphaUp.js'

import { falCalendarAlt } from './fontawesome-pro/output/falCalendarAlt.js'
import { falFileCertificate } from './fontawesome-pro/output/falFileCertificate.js'
import { falPlusCircle } from './fontawesome-pro/output/falPlusCircle.js'
import { falShoppingCart } from './fontawesome-pro/output/falShoppingCart.js'

library.add(
  fasAddressCard,
  fasAngleLeft,
  fasAngleRight,
  fasBuilding,
  fasCaretSquareDown,
  fasCheckCircle,
  fasCircle,
  fasCircleNotch,
  fasCity,
  fasEnvelope,
  fasFingerprint,
  fasFlagAlt,
  fasHandReceiving,
  fasIdBadge,
  fasMapMarker,
  fasMapPin,
  fasMinusSquare,
  fasMobile,
  fasPhone,
  fasPhoneOffice,
  fasPlusSquare,
  fasPrint,
  fasSearch,
  fasSignIn,
  fasSignOut,
  fasTimes,
  fasThumbsUp,
  fasToggleOn,
  fasToggleOff,
  fasTruck,
  fasUndo,
  farArrowLeft,
  farBadgePercent,
  farCalendarAlt,
  farCoin,
  farCoins,
  farDownload,
  farGlobeAfrica,
  farMinusCircle,
  farSortAlphaDown,
  farSortAlphaUp,
  falCalendarAlt,
  falFileCertificate,
  falPlusCircle,
  falShoppingCart
)
