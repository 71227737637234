import React from 'react'
import styled, { css } from 'styled-components'
import { boxShadow, LINK_COLOR } from '../../js'
import StyleButton from './StyleButton/StyleButton'
import Icon from './Icon'

const CreateButton = defaultProps => props => <IconButton {...defaultProps} {...props} />

export const IconButton = ({ children, component, icon, iconStyle, captionStyle, ...props }) => {
  const Component = component || 'button'
  return (
    <Component type="button" {...props}>
      {icon && <Icon icon={icon} style={iconStyle} />}
      {children && <span style={captionStyle}>{children}</span>}
    </Component>
  )
}

export const Button = CreateButton({
  iconStyle: { marginRight: 5 },
  style: { padding: 5 },
})

const orderButtonStyle = { fontSize: 24, padding: 12 }
export const OrderButton = props => (
  <StyleButton
    type="submit"
    {...props}
    backgroundFrom="hsl(95, 60%, 80%)"
    backgroundTo="hsl(95, 60%, 70%)"
    hoverBackgroundFrom="hsl(95, 60%, 90%)"
    hoverBackgroundTo="hsl(95, 60%, 80%)"
    style={orderButtonStyle}
  />
)

export const CommonStyle = css`
  border-radius: 0;
  box-sizing: border-box;
  font-family: inherit;
  transition: box-shadow 0.45s, border-color 0.45s ease-in-out;
  transition-property: box-shadow, border-color;
  transition-duration: 0.45s, 0.45s;
  transition-timing-function: ease, ease-in-out;
  transition-delay: 0s, 0s;
  padding: 0.5em;
  width: 100%;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  &:focus {
    outline: none;
  }

  ${({ valid }) =>
    valid === undefined
      ? css`
          background-color: #fff;
          border: 1px solid hsl(0, 0%, 80%);
          color: rgba(0, 0, 0, 0.75);
          &:disabled {
            background-color: hsl(0, 0%, 94%);
          }
          &:focus {
            background-color: hsl(0, 0%, 98%);
            box-shadow: 0 0 5px hsl(0, 0%, 60%);
            border-color: hsl(0, 0%, 60%);
          }
        `
      : valid
      ? css`
          background-color: hsl(120, 100%, 99%);
          border: 1px solid hsl(120, 100%, 30%);
          color: rgba(0, 0, 0, 0.75);
          &:disabled {
            background-color: hsl(120, 15%, 94%);
          }
          &:focus {
            background-color: hsl(120, 100%, 96%);
            box-shadow: 0 0 5px hsl(120, 100%, 25%);
            border-color: hsl(120, 100%, 25%);
          }
        `
      : css`
          background-color: hsl(0, 100%, 99%);
          border: 1px solid hsl(0, 100%, 30%);
          color: rgba(0, 0, 0, 0.75);
          &:disabled {
            background-color: hsl(0, 15%, 94%);
          }
          &:focus {
            background-color: hsl(0, 100%, 96%);
            box-shadow: 0 0 5px hsl(0, 100%, 25%);
            border-color: hsl(0, 100%, 25%);
          }
        `}
`

export const TextInput = styled.input.attrs({ type: 'text' })`
  ${CommonStyle}
`
export const PasswordInput = styled.input.attrs({ type: 'password' })`
  ${CommonStyle}
`
export const TextArea = styled.textarea`
  ${CommonStyle}
`
export const Select = styled.select`
  ${CommonStyle}
`
export const Caption = styled.strong``

export const FieldRow = styled.div`
  display: flex;
`

export const Description = styled.span`
  display: inline-block;
  margin-top: 4px;
`
export const Label = styled.label`
  display: inline-block;
  & > ${Caption}:first-child {
    &.required:after {
      content: ' *';
      font-weight: normal;
      color: red;
    }
    display: block;
  }
  & > ${TextInput} {
    margin-top: 4px;
  }
  & > ${TextArea} {
    margin-top: 4px;
  }
`

export const boxAppearance = css`
  background-color: #fafafa;
  border: 1px solid #ccc;
  box-shadow: ${boxShadow};
  user-select: none;
`

export const Fieldset = styled.fieldset`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  ${boxAppearance}
  margin: 0;
  margin-top: 20px;
  padding: 20px;
  padding-top: 6px;
  box-sizing: border-box;
`

export const Legend = styled.legend`
  background: #444;
  color: #ddd;
  font-weight: bold;
  padding: 5px;
`

const common = ({ zoom = 2 }) => css`
  @supports (zoom: ${zoom}) {
    zoom: ${zoom};
    margin-right: 2px;
  }
  @supports not (zoom: ${zoom}) {
    transform: scale(${zoom});
    margin-right: 10px;
  }
  align-self: center;
`

export const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  ${props => common(props)}
`

export const Radio = styled.input.attrs({ type: 'radio' })`
  ${props => common(props)}
`
export const Unit = styled.span`
  color: #555;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
`
export const PP = styled.p`
  font-size: larger;
  line-height: 1.4;
`

const printAndDownloadButtonStyle = { marginLeft: 5 }
export const PrintButton = ({ print = false, _, ...rest }) => (
  <StyleButton disabled={print} icon={['fas', 'print']} {...rest}>
    <span style={printAndDownloadButtonStyle}>{print ? _`$printing` : _`$print`}</span>
  </StyleButton>
)

export const DownloadButton = ({ download, _, ...rest }) => (
  <StyleButton disabled={download} icon={['far', 'download']} {...rest}>
    <span style={printAndDownloadButtonStyle}>{download ? _`Downloading PDF` : _`Download as PDF`}</span>
  </StyleButton>
)

export const Row = styled.div`
  display: flex;
`

export const Col = styled.div(
  ({ inline }) => css`
    display: ${inline ? 'inline-' : ''}flex;
    flex-direction: column;
  `
)

export const Link = styled.a`
  color: #444;
  text-decoration: none;
  &:hover {
    color: #000;
  }
`

export const HANDHELD_UPPER_LIMIT = 768
export const SMALL_SCREEN_LIMIT = 1000

export const SmartMarginContainer = styled.div`
  margin: 20px;
  @media screen and (max-width: ${SMALL_SCREEN_LIMIT}px) {
    margin-left: 10px;
    margin-right: 20px;
  }
  & p {
    color: #555;
    line-height: 1.4;
  }
  & p a {
    color: ${LINK_COLOR};
    text-decoration: none;
    &:hover {
      color: #555;
    }
  }
`
