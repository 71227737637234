import React, { Component } from 'react'

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    _mounted = false
    state = { component: null }

    async componentDidMount() {
      this._mounted = true
      const { default: component } = await importComponent()

      if (!this._mounted) return
      this.setState({ component: component })
    }

    componentWillUnmount() {
      this._mounted = false
    }

    render() {
      const C = this.state.component

      return C ? <C {...this.props} /> : null
    }
  }

  return AsyncComponent
}
