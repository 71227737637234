import React, { Component } from 'react'

import Icon from '../Icon'
import { IconButton } from './StyleButton.style'

class StyleButton extends Component {
  state = {
    mouseDown: false,
  }

  handleClick = e => {
    if (typeof this.props.onClick === 'function') {
      this.props.onClick()
    }
  }

  handleMouseDown = e => {
    if (typeof this.props.onMouseDown === 'function') {
      this.props.onMouseDown()
    }
    e.preventDefault()
    this.setState({ mouseDown: true })
  }

  handleMouseUp = () => {
    window.removeEventListener('mouseup', this.handleMouseUp, false)
    this.setState({ mouseDown: false })
  }

  renderIcon = (icon, size, iconStyle) => {
    if (React.isValidElement(icon)) return icon
    if ((Array.isArray(icon) && icon.length === 2 && typeof icon[0] === 'string' && typeof icon[1] === 'string') || typeof icon === 'string')
      return <Icon icon={icon} size={size} containerStyle={iconStyle} />
    return null
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.mouseDown && !this.state.mouseDown) {
      window.removeEventListener('mouseup', this.handleMouseUp, false)
    }
    if (!prevState.mouseDown && this.state.mouseDown) {
      window.addEventListener('mouseup', this.handleMouseUp, false)
    }
  }

  render() {
    const { mouseDown } = this.state
    const {
      type = 'button',
      active,
      disabled,
      icon,
      size,
      label = '',
      style,
      textStyle,
      children,
      backgroundFrom,
      backgroundTo,
      hoverBackgroundFrom,
      hoverBackgroundTo,
      title = '',
      iconStyle,
    } = this.props
    return (
      <IconButton
        type={type}
        mouseDown={mouseDown}
        disabled={disabled}
        active={active}
        onClick={this.handleClick}
        onMouseDown={this.handleMouseDown}
        backgroundFrom={backgroundFrom}
        backgroundTo={backgroundTo}
        hoverBackgroundFrom={!disabled && hoverBackgroundFrom}
        hoverBackgroundTo={!disabled && hoverBackgroundTo}
        style={style}
        title={title.length > 0 ? title : label}>
        {icon && this.renderIcon(icon, size, iconStyle)}
        {children}
        {!children && label && <span style={Object.assign({ marginLeft: icon ? 5 : 0 }, textStyle)}>{label}</span>}
      </IconButton>
    )
  }
}

export default StyleButton
