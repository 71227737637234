//import { homepage } from '../../package.json'
//export const apiRoot = ifProd(/\/$/.test(homepage) ? homepage.slice(0, homepage.length - 1) : homepage, window.location.origin)

const forceProd = false
const ifProd = (prodValue, devValue) => (!forceProd && process.env.NODE_ENV === 'development' ? devValue : prodValue)

//export const apiRoot = ifProd('https://nordtec.se/kalibrering/dynalab/api', window.location.origin)
//export const apiRoot = ifProd('https://www.nordtec.se/kalibrering/dynalab/api', window.location.origin)
//export const apiRoot = ifProd('https://www.nordtec.se/kalibrering/access/api', 'http://localhost:3001')
export const apiRoot = ifProd('', 'http://localhost:3001')

// Default options are marked with *
export const post = (url = ``, data = {}) =>
  fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: ifProd('same-origin', 'include'), // include, *same-origin, omit
    credentials: ifProd('include', 'include'), // include, *same-origin, omit
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
    body: JSON.stringify(data), // body data type must match 'Content-Type' header
  }).then(response => response.json())
//.catch(err => ({ error: process.env.NODE_ENV === 'development' ? err : 'Could not parse response from server.' }))

export const get = (url = ``) =>
  fetch(url, {
    method: 'GET', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, cors, *same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //credentials: ifProd('same-origin', 'include'), // include, *same-origin, omit
    credentials: ifProd('include', 'include'), // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrer: 'no-referrer', // no-referrer, *client
  })
    .then(response => response.json())
    .catch(err => ({ error: process.env.NODE_ENV === 'development' ? err : 'Could not parse response from server.' }))

export default post
