import styled, { css } from 'styled-components'

const hsl = (h, s, l) => `hsl(${h},${s}%,${l}%)`

export const IconButton = styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  display: inline-block;
  white-space: nowrap;
  background-color: #eee;
  border: 1px solid rgb(159, 159, 159);
  ${({ mouseDown, onClick, backgroundFrom, backgroundTo, hoverBackgroundFrom, hoverBackgroundTo }) => css`
    background: linear-gradient(${(mouseDown && onClick ? [backgroundTo, backgroundFrom] : [backgroundFrom, backgroundTo]).join(',')});
    &:hover {
      background: linear-gradient(${(mouseDown && onClick ? [hoverBackgroundTo, hoverBackgroundFrom] : [hoverBackgroundFrom, hoverBackgroundTo]).join(',')});
    }
    & > div {
      transform: scale(${mouseDown && onClick ? '0.99' : '1'});
    }
  `}
  border-radius: 1px;
  ${({ active }) =>
    active &&
    css`
      box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.05);
      transform: scale(1.1);
      border-color: rgb(175, 175, 175);
    `}
  min-width: 50px;
  min-height: 40px;
  & > span {
    display: inline-block;
  }
`

IconButton.defaultProps = {
  backgroundFrom: hsl(0, 0, 96),
  backgroundTo: hsl(0, 0, 85),
  hoverBackgroundFrom: hsl(0, 0, 98),
  hoverBackgroundTo: hsl(0, 0, 90),
}
