import 'react-app-polyfill/ie9'
import 'whatwg-fetch'
import 'babel-polyfill'
import 'core-js/features/array/from'
import 'core-js/features/string/starts-with'
import 'core-js/features/string/ends-with'
import 'core-js/features/string/repeat'
import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './components/@/Icons'
import App from './components/App/App'
import { unregister } from './serviceWorker'
import elementClosest from 'element-closest'
elementClosest(window)

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
unregister()
