import { nbsp } from '../../js/translation'
export default [
  { $: 'start-title', expression: `Nordtec's${nbsp}Calibration${nbsp}Portal`, sv: `Nordtecs${nbsp}kalibreringsportal` },
  { $: 'start-subtitle', expression: `Start`, sv: `Startsida` },
  {
    $: 'start-welcome-1',
    expression: "Welcome to Access, Nordtec's portal for ordering calibrations.",
    sv: 'Välkommen till Access, Nordtecs portal för beställning av kalibreringar.',
  },
  { $: 'start-welcome-2', expression: 'Read more $ABOUT_ACCESS', sv: 'Läs mer $ABOUT_ACCESS' },
  {
    $: 'start-welcome-3',
    expression: 'Feel free to contact $EMAIL if you have ideas on how to make Access better.',
    sv: 'Hör gärna av dig till $EMAIL om du har tips på hur vi kan göra Access ännu bättre.',
  },
  { $: 'about', expression: "about Nordtec's calibration portal.", sv: 'om Nordtecs kalibreringsportal.' },
  {
    $: 'footer-closing-text',
    expression: "Access is Nordtec's customer portal with information about your equipment, booking and ordering of calibration.",
    sv: 'Access är Nordtecs kundportal för information om dina mätinstrument, bokning och beställning av kalibrering.',
  },
  { $: 'company-name', expression: 'Nordtec Instrument AB' },
  { $: 'telephone', expression: 'Tel. 031-704 10 70' },
  { $: 'company-slogan', expression: 'Your Measurement Technology Partner', sv: 'Din partner inom mätteknik' },
  { $: 'contact-email', expression: 'kalibrering@nordtec.se' },
  { expression: 'Cookie policy', sv: 'Cookie-policy' },
  { expression: 'Return to Nordtec', sv: 'Tillbaka till Nordtecs hemsida' },
  { expression: 'Return to Start Page', sv: 'Tillbaka till startsida' },
  { expression: 'Access Logo', sv: 'Access-logo' },
  { expression: 'Access+ Logo', sv: 'Access+-logo' },
  { $: 'login', expression: 'Log In', sv: 'Logga in' },
  { $: 'logout', expression: 'Log Out', sv: 'Logga ut' },
  { expression: 'Book Calibration', sv: 'Boka Kalibrering' },
  { $: 'me', expression: 'My Pages', sv: 'Mina Sidor' },
  { $: 'my-instruments', expression: 'My Instruments', sv: 'Mina instrument' },
  { expression: 'For Access+ customers', sv: 'För kunder med Access+' },
  { $: 'order-express-calibration', expression: 'Order Express Calibration', sv: 'Beställ Expresskalibrering' },
  { $: 'order-calibration', expression: 'Order Calibration/Service', sv: 'Beställ kalibrering/service' },
  { $: 'about', expression: 'About Access', sv: 'Om Access' },
  { $: 'time-booking', expression: 'Time booking', sv: 'Tidsbokning' },
  {
    $: 'normal-title',
    expression: 'Calibration/Service',
    sv: 'Kalibrering/Service',
  },
  {
    $: 'normal-description',
    expression: 'Expected delivery time: 10 working days after receipt.',
    sv: 'Förväntad leveranstid: 10 arbetsdagar efter mottagning.',
  },
  {
    $: 'express-description',
    expression: "Nordtec's express services. Expected delivery time: 2-3 working days after receipt, price supplement 50%.",
    sv: 'Nordtecs Expresstjänst. Förväntad leveranstid: 2-3 arbetsdagar efter mottagning, pristillägg 50%.',
  },
  {
    $: 'time-booking-description',
    expression: 'Time booking is available for customers with an account.',
    sv: 'Tidsbokning är tillgängligt för kunder med konto.',
  },
  { expression: 'Contact', sv: 'Kontakta' },
  { expression: 'for more information', sv: 'för mer information' },
  { expression: 'For Access customers', sv: 'För kunder med Access-konto' },
  { expression: 'For Access+ customers', sv: 'För kunder med Access+-konto' },
  { expression: 'For customer without Access', sv: 'För kunder utan Access-konto' },
  { expression: 'About', sv: 'Om' },
  { expression: 'Contact us', sv: 'Kontakta oss' },
  {
    $: 'about-top',
    expression:
      'Nordtec uses an advanced digital platform for calibration and instrument management. This means, among other things, that you receive the originals of all calibration certificates via e-mail in an encrypted and locked PDF format and that you receive automatic reminders when it is time for calibration. You can request your own calibration intervals and we also provide your instruments with QR codes which you can scan with your mobile to see the current certificate or certificate. Just to mention some of the benefits...',
    sv:
      'Nordtec använder sig av en avancerad digital plattform för kalibrerings- och instrumenthantering. Det innebär bland annat att du får originalen av alla kalibreringsbevis och -certifikat via e-post i ett krypterat och låst PDF-format och att du får automatiska påminnelser när det är dags för kalibrering. Du kan begära egna kalibreringsintervall och dessutom förser vi dina instrument med QR-koder som du kan skanna med mobilen för att se det aktuella beviset eller certifikatet. Bara för att nämna några av alla fördelar...',
  },
  {
    $: 'about-access',
    expression:
      'Access is a free portal for ordering calibrations on our digital platform. With an Access account you do not have to fill in all the information every time it is time for calibration. If you do not yet have an account, select "Order Calibration" on the Home page and follow the instructions. Then select the option "I want an account!" in the order form, if you want us to create an account for you.',
    sv:
      'Access är en kostnadsfri portal för att beställa kalibreringar på vår digitala plattform. Med ett Access-konto slipper du fylla i alla uppgifter varje gång det är dags för kalibrering. Om du ännu inte har något konto väljer du "Beställ kalibrering" på startsidan och följer instruktionerna. Välj sedan alternativet "Jag vill ha ett konto!" i beställningsformuläret, om du vill att vi ska skapa ett konto åt dig.',
  },
  {
    $: 'about-access-plus',
    expression:
      "Access+ is a payment service that gives you full access to all previous calibrations and to your instruments and probes. You can open and view previous calibration certificates, see when it is time for the next calibration and manage all your registered instruments and probes. So you don't have to keep track of all this in outdated computer systems or in long, complicated Excel sheets. Contact us for a quote.",
    sv:
      'Access+ är en betaltjänst som ger dig full tillgång till alla tidigare kalibreringar och till dina instrument och givare. Du kan öppna och titta på tidigare kalibreringsbevis och -certifikat, se när det är dags för nästa kalibrering och hantera alla dina registrerade instrument och givare. Du slipper alltså hålla reda på allt sådant i föråldrade datasystem eller i långa krångliga Excel-ark. Hör av dig till oss för en offert.',
  },
  { expression: 'our form', sv: 'vårt formulär' },
  {
    $: 'about-bottom',
    expression: 'If you have any questions, you are welcome to contact us. Send an inquiry via $OURFORM or email to $EMAIL. You can also call us at $TEL.',
    sv:
      'Om du har några frågor är du välkommen att höra av dig. Skicka en fråga via $OURFORM eller mejla till $EMAIL. Det går också bra att slå en signal till $TEL.',
  },
  { $: 'about-email', expression: 'kalibrering@nordtec.se' },
  { $: 'tel', expression: '031-704 10 70' },
  { expression: 'CLOSE', sv: 'STÄNG' },
  { $: 'logged-out', expression: 'You have been logged out.', sv: 'Du har loggats ut.' },
]
