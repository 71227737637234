export const nbsp = '\xa0'
export const nbhyphen = '\u2011'
export const normalize = str => (str || '').toLowerCase().replace(/&nbsp;/g, ' ')
export const merge = (strings, ...values) => strings.reduce((str, curr, i) => `${str}${curr}${values[i] || ''}`, '')
export const translator = (language, options = { strings: true, values: true, dictionary: [], dictionaries: [] }) => {
  const { strings: translateStrings = true, values: translateValues = true, dictionary = [], dictionaries = [] } = options
  const _dictionary = [...dictionary, ...dictionaries.reduce((arr, x) => arr.concat(x), [])]
  function translate(strings, ...values) {
    if (typeof strings === 'string' && values.length === 0) {
      return translate([strings], undefined)
    }
    return (strings || [])
      .reduce((str, curr, i) => {
        if (strings.length > 1) {
          const fn = (_dictionary.find(
            x =>
              x.expression.replace(/&nbsp;/g, ' ').replace(/&nbhyphen;/g, '-') ===
              strings
                .join('_')
                .replace(/&nbsp;/g, ' ')
                .replace(/&nbhyphen;/g, '-')
          ) || {})[language]
          const newValues = translateValues
            ? values.map(value => (_dictionary.find(x => normalize(x.expression) === normalize(`${value}`)) || {})[language] || value)
            : values
          if (translateStrings && fn) {
            return fn(...newValues)
          }
          const merged = merge(strings, ...newValues)
          const translated = (_dictionary.find(x => normalize(x.expression) === normalize(`${merged}`)) || {})[language] || merged
          return translated
        } else {
          if ((strings[0] || '')[0] === '$') {
            const id = (strings[0] || '').slice(1)
            const item = _dictionary.find(x => x.$ === id) || {}
            return `${str}${item[language] || item.expression || ''}`
          } else {
            const newString = translateStrings ? (_dictionary.find(x => normalize(x.expression) === normalize(curr)) || {})[language] || curr : curr
            const newValue =
              (translateValues ? (_dictionary.find(x => normalize(x.expression) === normalize(values[i] || '')) || {})[language] : undefined) ||
              (values[i] || '')
            return `${str}${newString}${newValue}`
          }
        }
      }, '')
      .replace(/&nbsp;/g, nbsp)
      .replace(/&nbhyphen;/g, nbhyphen)
  }
  return translate
}

export default translator
